@import "variables";

.input {
  width: 300px;
  height: 40px;
  padding: 0 0 0 10px;
  border: 1px solid $buttonBorderColor;
  border-radius: $buttonBorderRadius;
  font-family: $normalTextFont;
  font-size: $normalFontSize;
  box-sizing: border-box;

  &:hover {
    border-color: $hoveredFormBorderColor;
  }

  &::placeholder {
    color: $placeholderColor;
    font-size: 1em;
    font-family: $normalTextFont;
  }

  &:focus,
  &:focus-visible {
    border: 1px solid $focusedInputBorderColor;
    outline: none;
  }

  &::selection {
    background-color: $inputSelectionColor;
  }
}
