@import "variables";

.imageTile {
  width: 180px;
  height: 115px;
  padding-top: 15px;
  padding-bottom: 20px;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $imageTextButtonColor;

  border-width: 2px;
  border-color: transparent;
  border-radius: $buttonBorderRadius;
  border-style: solid;

  font-size: $normalFontSize;

  &:hover {
    border-color: $hoveredImageTextButtonBorderColor;
  }
}

.imageTile__active {
  @extend .imageTile;
  border-color: $hoveredImageTextButtonBorderColor;
}
