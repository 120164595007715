@import "variables";

.button {
  position: relative;
  box-sizing: border-box;
  border: 2px solid $radioButtonBorderColor;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  &__active {
    @extend .button;
    border-color: $activeRadioButtonBorderColor;
  }

  .inner {
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translateX(-50%) translateY(-50%);

    border-radius: 50%;

    &__active {
      @extend .inner;
      background-color: $activeRadioButtonBorderColor;
    }
  }
}
