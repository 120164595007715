.textArea {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 5px 10px;

  height: 60px;

  border-width: 1px;
  border-style: solid;
  border-color: #aaaaaa;
  border-radius: 3px;

  box-shadow: none;
  outline: none;
  resize: none;

  &:hover {
    border-color: #aaaaaa;
  }

  &:focus {
    border-color: #ff7b2c;
  }

  &::selection {
    background-color: #ffebdc;
  }
}
