.unorderedList {
  list-style-type: disc;
  margin: 0;
  margin-left: 20px;

  text-align: left;

  .item:not(:last-child) {
    margin-bottom: 10px;
  }
}
