@import "variables";

.reportTable {
  box-sizing: border-box;
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #dddddd;
  .header {
    color: white;
    font-size: 13px;
    word-break: keep-all;
    height: 63px;
  }
}
