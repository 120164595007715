@import "variables";

.modalOverlay {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #66666666;
  z-index: $notificateModalZIndex;
}

.modalContainer {
  margin: 100px auto;
  padding: 30px;
  width: 600px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 3px;
  outline: none;
}
