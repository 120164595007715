@import "variables";

.tagArea {
  vertical-align: middle;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .form {
    margin-right: 10px;
    width: 100%;
    display: inline-block;

    .formInput {
      width: 100%;
      height: 30px;
      margin: 0;
      padding: 0 0 0 10px;
      box-sizing: border-box;
      border: 1px solid #cccccc;
      border-radius: 3px;
      background: #ffffff 0% 0% no-repeat padding-box;

      &:hover {
        border-color: $hoveredFormBorderColor;
      }

      &:focus,
      &:focus-visible {
        border: 1px solid $focusedInputBorderColor;
        outline: none;
      }

      &::selection {
        background-color: $inputSelectionColor;
      }
    }
  }

  .copyButton {
    display: inline-block;
    width: 100%;
  }
}
