.ContentsEditPhaseFormMain {
  display: flex;
  min-height: 677px;

  .preview {
    resize: horizontal;
  }

  .form {
    flex: 1;
  }
}
