@import "variables";

.description {
  font: normal 13px/20px $normalTextFont;
  p {
    margin: 0;
  }
  .notice {
    font: normal 11px/17px Meiryo;
  }
}
