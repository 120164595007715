.notificationTitle {
  .titleArea {
    display: flex;
    align-items: center;

    margin-bottom: 10px;

    word-break: break-all;

    .mark {
      margin-right: 5px;
    }
  }

  .previewArea {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
