@import "variables";

.urlLocationForm {
  .main {
    display: flex;

    .location {
      margin-right: 10px;
      min-width: 400px;
    }

    .compareType {
      width: 110px;
      height: 30px;
    }
  }
}
