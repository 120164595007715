.rangeSlider {
  position: relative;
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cccccc;
    border-radius: 4px;

    min-width: 50px;

    line-height: 18px;

    cursor: pointer;

    .value {
      font-size: 10px;
      margin-right: 5px;
    }

    .valueDisabled {
      @extend .value;
      color: #cccccc;
    }

    .dropdown {
      width: 6px;
      height: 3px;
    }

    .dropdownDisabled {
      @extend .dropdown;
      fill: #cccccc;
      stroke: #cccccc;
    }
  }

  .boxDisabled {
    @extend .box;
    cursor: initial;
  }

  .slider {
    position: absolute;
    z-index: 99999;
  }

  .blurArea {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99999;
  }
}
