.simpleSliderColorPicker {
  box-sizing: border-box;

  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    .colorCircle {
      margin-right: 15px;
    }

    .sliders {
      .hue {
        position: relative;
        width: 186px;
        height: 15px;
        margin-bottom: 15px;

        .slider {
          width: 16px;
          height: 16px;
          background-color: white;
          border-radius: 16px;
          box-shadow: 0 0 5px #00000066;
          transform: translate(-50%, 0);
        }
      }

      .saturation {
        position: relative;
        width: 186px;
        height: 70px;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;

    .editableInput {
      margin-right: 10px;
    }
  }
}
