.pushNotificationContentsDemonstration {
  height: 100%;
  display: flex;
  flex-direction: column;

  .preview {
    flex: 1 1 0;
    background-color: #ffffff;

    padding: 20px;

    .title {
      margin-bottom: 10px;
    }

    .previewer {
      margin-bottom: 5px;
    }

    .note {
      color: #666666;
    }
  }

  .test {
    background-color: #ffffff;
    border-top: 1px solid #dddddd;

    padding: 20px;

    display: flex;
    justify-content: right;
    align-items: center;

    .item:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.pushNotificationForms {
  .item:not(:last-child) {
    margin-bottom: 20px;
  }
}

.pushNotificationContentsEditor {
  display: flex;
  justify-content: space-between;

  padding: 10px;
  background-color: #f4f4f4;

  .form {
    padding: 10px 30px 10px 10px;
  }
}
