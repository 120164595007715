.grayButton {
  // shape
  width: 100%;
  height: 30px;
  padding: 5px 10px;
  border-radius: 3px;

  // color
  color: #ffffff;
  background-color: #aaaaaa;

  // decoration
  text-decoration: none;
  border: none;

  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: #999999;
  }
}
