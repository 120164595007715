@import "variables";

.passwordForm {
  text-align: left;

  .label {
    display: block;
    font-size: $normalFontSize;
    line-height: $normalLineHeight;
    font-family: $normalTextFont;
    margin-bottom: 5px;
  }

  .form {
    width: 300px;
    height: 40px;
    border: 1px solid $formBorderColor;
    border-radius: $buttonBorderRadius;
    box-sizing: border-box;

    &:hover,
    &:focus {
      border-color: $hoveredFormBorderColor;
    }
  }
}
