.presetFormatSelectPhaseFormMain {
  padding: 30px 0px 50px;

  .headline {
    margin-bottom: 20px;
  }

  .buttons {
    .row {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0px;
      }

      .button {
        margin-right: 20px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}
