.modeSelectPhaseFormMain {
  padding: 30px 0px 50px;

  .headline {
    margin-bottom: 20px;
  }

  .buttons {
    display: flex;
    justify-content: center;

    .button {
      min-width: 0;
      overflow-wrap: break-word;

      margin-right: 40px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
