@import "variables";

.authTemplate {
  margin: 135px auto 0;

  .main {
    width: 400px;
    padding: 40px 0px;
    margin: 0px auto 45px;

    background-color: white;
    border-radius: 10px;
    border: 0px solid #eee;
    box-shadow: 0px 0px 10px 5px rgba(241, 240, 239, 0.8);

    .logo {
      margin-bottom: 30px;
    }

    .form {
      // width: 300px;
      margin: 0 auto;
      padding: 0 50px;
    }
  }
}
