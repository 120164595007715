@import "variables";

.th {
  padding: 10px 15px;

  font-weight: bold;
  font-size: $normalFontSize;
  line-height: $tableHeaderLineHeight;
  font-family: $normalTextFont;
  white-space: nowrap;
  text-align: left;

  &:first-child {
    padding-left: 15px;
    border-left: 0;
  }

  &:last-child {
    padding-right: 30px;
  }
}

.th_right {
  @extend .th;
  text-align: right;
  min-width: 60px;
}
