@import "variables";

.unavailableBoard {
  padding: 10px 15px;
  width: 234px;

  text-align: center;
  background-color: $boardBackgroundColor;

  .icon {
    margin-bottom: 5px;
  }

  .text {
    font-size: $normalFontSize;
  }
}
