.actionDetailReportTable {
  box-sizing: border-box;
  width: 100%;
  border: 0px solid transparent;
  display: flex;

  .fixed {
    overflow-x: scroll;
  }
}
