@import "variables";

.tileLayoutView {
  text-align: left;
  .title {
    margin-bottom: 5px;
    font-size: $normalFontSize;
    font-weight: bold;
  }

  .tiles {
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    .tile {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
}
