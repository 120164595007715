@import "variables";

.dateRangePickerOpener {
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;
  width: 146px;
  height: 30px;
  padding: 5px 10px;
  border: 1px solid $dateRangePickerBorderColor;
  border-radius: 3px;
  background-color: $calenderBackgroundColor;

  cursor: pointer;
  outline: none;

  &:hover,
  &.is-active {
    border-color: $hoveredFormBorderColor;
  }

  &:focus,
  &:active {
    outline: none;
  }

  .buttonLeft {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      margin-right: 10px;
    }

    .dateRange {
      font-size: $normalFontSize;
      line-height: $normalLineHeight;
    }
  }

  .buttonRight {
    display: flex;
    justify-content: right;
    align-items: center;
    padding-bottom: 1px;
  }

  img {
    vertical-align: middle;
  }
}
