.verticalSpreadLayout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  .item {
    width: 100%;
  }

  .item:not(:last-child) {
    margin-bottom: 5px;
  }
}
