@import "variables";

.iconButton {
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;

  &:hover {
    filter: brightness(110%);
    cursor: pointer;
  }

  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &__disabled {
    @extend .iconButton;
    cursor: default;
    &:hover {
      cursor: default;
    }
  }
}
