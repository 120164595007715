.items {
  background-color: #ff7b2c;
  border: 1px solid white;
  height: 60px;

  &:last-child {
    border-right: 0px solid transparent;
  }

  .item {
    display: flex;
    padding: 5px 15px;
  }

  .nestedItem {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid white;

    .label {
      display: inline-block;
    }

    .icon {
      display: inline-block;
      margin-left: 10px;
      cursor: pointer;
    }

    .nestedItemChild {
      display: flex;
      min-width: 70px;
      padding: 5px 15px;
      border-right: 1px solid white;
      flex: 1;

      &:last-child {
        border-right: 0px solid transparent;
      }
    }
  }
}
.sortIcon :hover {
  cursor: pointer;
}
