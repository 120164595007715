.updateButton {
  .icon {
    display: inline-block;
    vertical-align: middle;
    line-height: 20px;
    margin-right: 5px;
  }
}

.deliveryReportStatusTable {
  min-height: 570px;
  .header {
    margin-bottom: 5px;
  }
}
