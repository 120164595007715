.horizontalLayout {
  display: flex;
  justify-content: left;
  align-items: center;

  .item {
    display: flex;
    align-items: center;
  }

  .item:not(:last-child) {
    margin-right: 10px;
  }
}
