.layout {
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 30px;

  .item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}
