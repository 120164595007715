.completePhaseDetailTableView {
  padding: 20px 30px;

  .headline {
    margin-bottom: 10px;
  }

  .table {
    text-align: left;
  }
}
