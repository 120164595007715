@import "variables";

.actionCreationForm {
  .form {
    border: 1px solid $tableBorderColor;
    border-radius: $tableBorderRadius;
    margin-bottom: 20px;

    .footer {
      display: flex;
      justify-content: space-between;
      background-color: $formFooterColor;
      padding: 10px 30px 10px;
      border-top: 1px solid $tableBorderColor;
      border-bottom-left-radius: $tableBorderRadius;
      border-bottom-right-radius: $tableBorderRadius;
    }

    .main {
      background-color: white;
      border-top-left-radius: $tableBorderRadius;
      border-top-right-radius: $tableBorderRadius;
    }
  }

  .commonButtons {
    display: flex;
    justify-content: right;
    .button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
