.cautionMessage {
  display: flex;
  align-items: center;
  .icon {
    margin-right: 5px;
    .image {
      vertical-align: middle;
    }
  }
}
