@import "variables";

.loadingView {
  width: 100%;
  height: 100px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  .elements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon {
      margin-bottom: 20px;
    }
  }
}
