.grantMessageEditPageLayout {
  box-sizing: border-box;

  padding: 30px;

  .title {
    margin-bottom: 20px;
    display: flex;
    justify-content: left;
  }

  .main {
    max-width: 1010px;

    .errorMessages {
      margin-bottom: 10px;
    }

    .editor {
      display: flex;
      background-color: #ffffff;
      border-radius: 5px;

      max-height: 580px;

      margin-bottom: 20px;

      .preview {
        min-width: 570px;
        border-right: 1px solid #ddd;
        overflow: hidden;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      .edit {
        box-sizing: border-box;
        min-width: 440px;

        padding: 30px 20px 200px 30px;
        overflow-y: scroll;
      }
    }

    .button {
      display: flex;
      justify-content: right;
    }
  }
}
