@import "variables";

body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-size: $normalFontSize;
  line-height: $normalLineHeight;
  font-family: $normalTextFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $masterBackgroundColor;
  box-sizing: border-box;
}

#root {
  min-width: $masterWidth;
  min-height: $masterHeight;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: $scrollBarThumbColor 0% 0% no-repeat padding-box;
}

::-webkit-scrollbar-track {
  background: transparent;
}
