.horizontalSlider {
  display: flex;
  align-items: center;

  .slider {
    margin-right: 5px;
  }

  .monitor {
    font-size: 10px;
    padding: 1px 5px;
    background-color: #f4f4f4;
  }
}
