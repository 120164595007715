.gradationColorPicker {
  position: relative;

  .opener {
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #dddddd;
    cursor: pointer;
  }

  .popover {
    position: absolute;
    z-index: 2;

    left: -10px;
    background-color: #fff;
    border: 1px solid #aaaaaa;
    padding: 10px 15px;

    .selector {
      margin-bottom: 10px;
    }
  }

  .cover {
    z-index: 2;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}
