@import "variables";

.precautionaryIcon {
  background-color: $precautionaryIconColor;
  border-radius: 50px;
  padding: 0px 10px;
  box-sizing: border-box;

  font-size: $noticeFontSize;
  line-height: $normalLineHeight;
  white-space: nowrap;
}
