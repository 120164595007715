.contentsPreviewArea {
  position: relative;
  height: 100%;

  .iframe {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-width: 0;
    border-radius: 0px;
    pointer-events: none;
  }

  .contents {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: transparent;
    z-index: 2;

    .frame {
      border: hidden;
      width: 100%;
      height: 100%;
    }
  }
}
