.pushNotificationTestSettingDescription {
  .introduce {
    margin-bottom: 10px;
  }
  .procedure {
    padding: 20px;
    background-color: #f4f4f4;
    margin-bottom: 10px;

    .item {
      display: flex;

      .number {
        margin-right: 4px;
      }

      .url {
        color: #ff5c38;
      }

      .sentence {
        .note {
          line-height: 16px;
          color: #666666;
        }
      }
    }

    .item:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .notices {
    color: #666666;
    line-height: 16px;
  }
}
