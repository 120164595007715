@import "variables";

.linkText {
  color: $linkColor;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
