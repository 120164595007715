.numberInputForm {
  display: flex;
  align-items: center;

  .input {
    width: 50px;
    margin: 0px 10px;
  }

  &__disabled {
    @extend .numberInputForm;
    filter: opacity(0.3);
  }
}
