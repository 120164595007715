@import "variables";

.transparentButton {
  background-color: transparent;
  border: none;
  border-radius: 3px;
  height: 30px;
  padding: 5px 10px;

  color: $negativeButtonTextColor;

  cursor: pointer;
  white-space: nowrap;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    background-color: $hoveredNegativeButtonColor;
  }
}
