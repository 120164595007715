.colorCircle {
  width: 30px;
  height: 30px;

  .circle {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    user-select: none;
    border: 1px solid #dddddd;
    &:hover {
      transform: scale(1.2);
    }
  }

  .check {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .circle__specific {
    @extend .circle;
    border: 1px solid #dddddd;
  }
}
