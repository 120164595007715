.marginForm {
  display: flex;

  .title {
    margin-right: 10px;
  }

  .forms {
    display: flex;

    justify-content: center;
    align-items: center;

    .form {
      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 10px;
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
