.underBarTitleForm {
  .title {
    margin-bottom: 10px;
  }

  .elements {
    .element {
      margin-bottom: 10px;
    }
  }
}
