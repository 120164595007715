.decorationFormView {
  padding: 20px 30px;

  .header {
    margin-bottom: 20px;
  }

  .elem {
    margin-bottom: 20px;
  }
}
