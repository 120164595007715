@import "variables";

.segmentationRuleDetail {
  width: 100%;
  .title {
    display: flex;
    margin-bottom: 10px;
  }

  .table {
    width: 100%;
    height: 400px;
    padding-right: 10px;
    box-sizing: border-box;
    overflow-y: auto;
  }

  .closeButton {
    margin-top: 20px;
    padding-right: 20px;
    text-align: right;
  }
}
