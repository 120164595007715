@import "variables";

#timePicker .react-datepicker {
  box-sizing: border-box;
  &__time {
    &-container {
      box-sizing: border-box;
      // width: 90px;
    }

    &-box {
      box-sizing: border-box;
      width: 90px;
    }

    &-list {
      text-align: left;
      &-item {
        height: auto;
        padding: 5px 15px;
        color: $textColor;
        box-sizing: border-box;
        width: 88px;

        &:hover,
        &--selected {
          background-color: $hoveredItemColor;
          color: $textColor;
        }
      }
    }
  }

  &-popper {
    box-sizing: border-box;
    width: 90px;
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
}
