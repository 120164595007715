.grantMessagePublishStatus {
  display: flex;
  align-items: center;

  .item {
    display: flex;
    align-items: center;
  }

  .item:not(:last-child) {
    margin-right: 5px;
  }
}
