@import "variables";

.cell {
  box-sizing: border-box;
  border-right: 1px solid #cccccc;
  padding: 0;
  .item {
    padding: 0px 16px;
  }

  .nestedItem {
    display: flex;
    justify-content: space-between;

    .item {
      flex: 1;
      min-width: 70px;

      padding: 10px 16px;

      border-right: 1px solid #cccccc;

      &:last-child {
        border-right: 0px solid transparent;
      }
    }
  }

  .itemAlignCenter {
    display: flex;
    justify-content: center;
  }

  .itemAlignLeft {
    display: flex;
    text-align: left;
  }

  .itemAlignRight {
    display: flex;
    justify-content: right;
  }

  .cvrImproveRateUp {
    color: #00b507;
    display: flex;
    justify-content: right;
  }

  .cvrImproveRateDown {
    color: #d03f3f;
    display: flex;
    justify-content: right;
  }

  .cvrImproveRateEven {
    color: #000000;
    display: flex;
    justify-content: right;
  }
}

.arrowIcon {
  width: 6.5px;
  padding-left: 5px;
}

.fixedNo {
  @extend .cell;

  position: sticky;
  left: 0;
  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-right: 1px solid #cccccc;
  }
}

.fixedName {
  @extend .cell;
  position: sticky;
  left: 58px;
  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-right: 1px solid #cccccc;
    box-shadow: 5px 10px 10px 0px #eeeeee;
  }
}
