.panelListLayout {
  width: 1010px;
  text-align: left;

  .title {
    margin-bottom: 20px;
  }
  .panels {
    .panel:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
