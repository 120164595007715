.authFormLayoutV2 {
  .headline {
    margin-bottom: 10px;
  }

  .sentence {
    margin-bottom: 20px;
  }

  .button {
    display: flex;
    justify-content: center;
  }
}
