.orangeWideButton {
  // shape
  width: 100%;
  padding: 10px;
  border-radius: 3px;

  // color
  color: #ffffff;
  background-color: #ff7b2c;

  // decoration
  text-decoration: none;
  border: none;

  // text
  font-size: 13px;

  &:hover {
    background-color: #ff5c38;
    cursor: pointer;
  }
}
