@import "variables";

.radioButton {
  display: flex;
  align-items: center;

  cursor: pointer;

  .button {
    margin-right: 5px;
  }
}
