@import "variables";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
  padding: 0px 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-bottom: thin solid $tableBorderColor;

  .headerLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    opacity: 1;

    .serviceLogo {
      display: flex;
      align-items: center;
      margin-right: 30px;
    }

    .loginUser {
      margin-right: 10px;
      font-size: $normalFontSize;
      line-height: $normalLineHeight;
      font-family: $normalTextFont;
      white-space: nowrap;
    }

    .logout {
      margin-right: 10px;
    }
  }
}
