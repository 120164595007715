@import "variables";

.sideBarTab {
  height: 40px;
  width: 100%;

  border-radius: 0px 50px 50px 0px;
  text-align: left;

  cursor: pointer;

  .text {
    padding: 10px 0px 10px 30px;
  }

  &:hover {
    background: $activeSideBarButtonColor 0% 0% no-repeat padding-box;
  }

  &__active {
    @extend .sideBarTab;
    background: $activeSideBarButtonColor 0% 0% no-repeat padding-box;
  }
}

.SideBar {
  padding-top: 20px;
  padding-right: 20px;
  width: 200px;
  height: 100%;

  background-color: #ffffff;

  .tab:not(:last-child) {
    margin-bottom: 10px;
  }
}
