.presetColorPicker {
  box-sizing: border-box;
  width: 270px;
  padding: 10px 15px;

  .circleArea {
    margin-bottom: 5px;

    .circleRow {
      display: flex;
      margin-bottom: 5px;

      .circleElem {
        margin-right: 5px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;

    .editableInput {
      margin-right: 10px;
    }
  }
}
