.checkBoxRangeSliderForm {
  display: flex;
  .name {
    margin-right: 10px;
  }

  .slider {
    margin-right: 10px;
  }
}
