@import "variables";

.list {
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap; /* 改行したら崩れるので */
  display: inline-block;
  z-index: 1;

  .list_item {
    font-size: 13px;
    font-weight: 700;
    position: relative;
    display: inline-block;
    cursor: default;

    background: #eee;

    &::after {
      content: "";
      display: block;
      height: 31px;
      width: 31px;
      transform: rotate(45deg) skew(15deg, 15deg);
      position: absolute;
      top: -2px;
      z-index: -1;
      right: -15px;
      background: inherit;
      border-top: 5px solid #fbfbfb;
      border-right: 5px solid #fbfbfb;
      border-bottom: none;
      border-left: none;
    }

    &:nth-of-type(1) {
      z-index: 5;
      border-radius: 50px 0 0 50px;
    }
    &:nth-of-type(2) {
      z-index: 4;
    }
    &:nth-of-type(3) {
      z-index: 3;
    }
    &:nth-of-type(4) {
      z-index: 2;
      border-radius: 0 50px 50px 0;
      padding-right: 1.1em;

      &::after {
        content: normal;
      }
    }

    .textbox {
      display: flex;
      padding: 0.5em 0.4em 0.5em 1.5em;
      position: relative;

      .description {
        margin-right: 5px;

        .index {
          display: inline-block;
          vertical-align: middle;
          font-size: 10px;
          font-weight: 700;
          border-radius: 8px;
          width: 16px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          margin-right: 5px;
          color: $masterBackgroundColor;
          background: #aaa;
        }
      }

      .edit {
        display: flex;
        align-items: center;
      }
    }
  }

  .list_item_active {
    @extend .list_item;
    background: #ffebdc;
    cursor: default;
    .textbox {
      .description {
        .index {
          background: #ff7b2c;
        }
      }
    }
  }

  .editable {
    &:hover {
      background: #ffebdc;
      cursor: pointer;
      .textbox {
        .description {
          .index {
            background: #ff7b2c;
          }
        }
      }
    }
  }
}
