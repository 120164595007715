.measurementUrlSettingTable {
  width: 100%;

  word-break: break-all;

  .locationArea {
    width: 100%;
  }

  .parameterArea {
    .parameterBox {
      background-color: #ffffff;
      border: 1px solid #cccccc;
      padding: 10px;

      border-radius: 3px;
    }

    .separator {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
