@import "variables";

.contentsPreviewView {
  display: flex;
  flex-direction: column;
  border-right: 1px solid $tableBorderColor;
  height: 100%;

  .previewHeader {
    display: flex;
    align-items: center;
    background-color: $inputBackgroundColor;
    border-bottom: 1px solid $tableBorderColor;
    padding: 10px 9px;

    .modeSelect {
      margin-right: 10px;
    }

    .urlInput {
      flex: 1;
    }
  }

  .previewArea {
    flex: 1;
  }
}
