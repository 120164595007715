@import "variables";

.activeCheckBox {
  width: 15px;
  height: 15px;
  position: relative;

  .box {
    position: relative;
    box-sizing: border-box;
    width: 15px;
    height: 15px;

    background-color: $checkboxButtonColor;
    border-radius: $checkboxBorderRadius;
  }

  .checkIcon {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
