.htmlPreviewer {
  position: relative;
  width: 100%;
  height: 100%;

  .contents {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: transparent;

    .frame {
      border: hidden;
      width: 100%;
      height: 100%;
    }
  }
}
