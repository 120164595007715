.timingTable {
  background-color: transparent;
  border-collapse: collapse;
  margin-bottom: 15px;
  //土曜列
  tr :nth-of-type(7) {
    color: #56a6d8;
  }

  //日曜列
  tr :nth-of-type(8) {
    color: #ff7b2c;
  }

  //祝日列
  tr :nth-of-type(9) {
    color: #ff7b2c;
  }

  .headerRow {
    text-align: center;
    border-bottom: 1px solid #ffebdc;
    .cell {
      font-weight: bolder;
    }
  }

  .row {
    background-color: white;
    border-bottom: 1px solid #ffebdc;
    .cell {
      padding: 5px 10px;
      text-align: center;
      line-height: 20px;
    }
  }
}
