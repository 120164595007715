.container {
  .dayOfWeek {
    display: inline-flex;
    .checkBox {
      padding-left: 10px;
    }
    .text {
      padding-left: 10px;
      color: #cccccc;
    }
  }
}
