@import "variables";

.LogOutButton {
  color: $linkColor;
  letter-spacing: 0px;
  vertical-align: middle;
  border: none;
  background-color: transparent;

  font-size: $normalFontSize;
  line-height: $normalLineHeight;
  font-family: $normalTextFont;

  cursor: pointer;
  white-space: nowrap;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
    border: none;
  }
}
