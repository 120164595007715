@import "variables";

.errorMessages {
  text-align: left;

  .errorMessage {
    color: $errorTextColor;
    display: inline-block;
    text-align: left;
    line-height: 1.2;
    margin-bottom: 10px;

    .icon {
      vertical-align: middle;
      margin-right: 5px;
    }

    .text {
      vertical-align: middle;
      font-size: $normalFontSize;
      font-family: $normalTextFont;
    }
  }
}
