.reportHeader {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .titleArea {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    .title {
      margin-right: 10px;
    }
    .updateTime {
      font-size: 10px;
    }
    .functionArea {
      display: inline-flex;
      justify-content: right;
      align-items: right;
    }
  }
}
