.verticalTable {
  width: 100%;
  height: 100%;
  border: 1px solid #dddddd;
  border-collapse: collapse;

  .row:nth-child(odd) {
    background-color: #fff7f1;
  }

  .row:nth-child(even) {
    background-color: #ffffff;
  }

  .header {
    padding: 15px;
    border: 0px solid transparent;
    border-right: 1px solid #dddddd;
    white-space: nowrap;
  }

  .data {
    padding: 15px;
    border: 0px solid transparent;
    word-break: break-all;
  }
}
