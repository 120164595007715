@import "variables";

.urlParameterForm {
  display: flex;
  margin-bottom: 5px;
  .elem {
    margin-right: 10px;
    width: 320px;
  }

  .compareType {
    width: 110px;
  }
}
