.title {
  font-size: 20px;
  line-height: 22px;
  font-weight: bold;
}

.section {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
}

.largeText {
  font-size: 16px;
  line-height: 20px;
}

.boldText {
  font-size: 13px;
  line-height: 13px;
  font-weight: bold;
}

.text {
  font-size: 13px;
  line-height: 13px;
  font-weight: normal;
  line-height: 20px;
}

.note {
  font-size: 11px;
  line-height: 16px;
  font-weight: normal;
}

.stats {
  font-size: 36px;
  font-weight: bold;
  line-height: 36px;
}
