.formItemView {
  text-align: left;
  margin-bottom: 20px;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    line-height: 20px;
    .text {
      margin-right: 10px;
    }
  }

  .divider {
    border-bottom: 1px solid #ff7b2c;
  }

  .colors {
    display: flex;
    margin-bottom: 5px;
    .color {
      margin-right: 20px;
    }
  }

  .texts {
    .paragraph {
      display: flex;
      margin-top: 10px;
      .element {
        margin-right: 20px;
      }
    }
  }
}
