.reportArea {
  width: 100%;
  margin-bottom: 20px;

  .reportAreaHeader {
    display: flex;

    margin-bottom: 10px;
    .title {
      margin-right: 10px;
    }
  }
}
