.blankMessage {
  box-sizing: border-box;
  width: 100%;
  height: 180px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #dddddd;
  border-top: 0px solid transparent;
  background-color: #ffffff;
}
