.orangeBorderedButton {
  // NOTE: font
  color: #ff7b2c;

  // NOTE: form
  box-sizing: border-box;
  border: 1px solid #ff7b2c;
  border-radius: 3px;
  padding: 5px 10px;
  background-color: #ffffff;

  &:hover {
    background-color: #ffebdc;
    cursor: pointer;
  }
}
