@import "variables";

.react-datepicker {
  position: relative;
  border: none;
  box-sizing: border-box;
  font-size: $normalFontSize;

  font-family: $normalTextFont;

  &__header {
    padding: 0;
    background-color: transparent;
    border: 0px solid white;
  }

  &__current-month {
    margin-bottom: 15px;
    font-weight: bold;
    line-height: $normalLineHeight;
  }

  &__day-name {
    margin: 3px;
    line-height: 22px;
    width: 22px;
    font-weight: bold;
  }

  &__day {
    // parameters related text is same with __day-name
    margin: 1px 3px;
    line-height: 22px;
    width: 22px;
    text-align: center;

    color: $textColor;
    letter-spacing: 0;
    outline: none;

    &--today {
      color: $dateRangePickerSelectedDateTodayTextColor;
    }

    &--in-range,
    &--in-selecting-range {
      background-color: $dateRangePickerInRangeDateColor;
    }

    &:hover {
      color: $textColor;
      background-color: $dateRangePickerInRangeDateColor;
    }

    &--selected,
    &--range-end {
      background-color: $dateRangePickerSelectedDateBackgroundColor;
      color: $dateRangePickerSelectedDateTextColor;
      border-radius: 5px;

      &:hover {
        background-color: $dateRangePickerSelectedDateBackgroundColor;
        color: $dateRangePickerSelectedDateTextColor;
      }
    }

    &--outside-month {
      color: $dateRangePickerDisabledDateTextColor;
      &:hover {
        background-color: transparent;
        color: $dateRangePickerDisabledDateTextColor;
      }
    }

    &--disabled,
    &--keyboard-selected {
      color: $disableTextColor;
      background-color: transparent;
      &:hover {
        background-color: transparent;
        color: $disableTextColor;
      }
    }
  }

  &__navigation {
    border: none;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    top: -3px;

    outline: none;

    &--previous {
      background-image: url(/images/icons/icon_arrow_left.svg);

      &:hover {
        opacity: 0.7;
      }

      &--disabled {
        opacity: 0.3;
      }
    }

    &--next {
      background-image: url(/images/icons/icon_arrow_right.svg);

      &:hover {
        opacity: 0.7;
      }

      &--disabled {
        opacity: 0.3;
      }
    }
  }

  &-popper {
    &[data-placement^="bottom"] {
      margin-top: 5px;
    }
  }
}
