@import "variables";

.footer {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: $footerFontSize;
  line-height: $footerLineHeight;
  font-family: $normalTextFont;

  width: 100%;
}
