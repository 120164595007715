.pushNotificationDeliveryScheduleForm {
  width: 100%;

  .notification {
    color: #666;
    margin-bottom: 5px;
  }

  .frequencySection {
    margin-bottom: 20px;

    .frequencyType {
      margin-bottom: 10px;
    }

    .form {
      .frequency {
        margin-bottom: 20px;
      }
    }
  }
}
