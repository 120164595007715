.grantMessageStatsPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .rate {
    display: flex;
    justify-content: left;
    align-items: center;

    .mark {
      margin-right: 10px;
    }
  }

  .details {
    text-align: right;
    .item {
      display: flex;
      align-items: center;
      justify-content: right;
      .label {
        margin-right: 20px;
      }
      .data {
        width: 90px;
      }
    }
  }
}
