.titleForm {
  .title {
    margin-bottom: 5px;
  }

  .main {
    margin-bottom: 5px;
    min-width: 400px;
  }

  .notification {
    color: #666666;
  }
}
