.loginErrorCountForm {
  .count {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .form {
      width: 48px;
      margin-right: 10px;
    }
  }

  .isErrorPage {
    margin-bottom: 5px;
  }
}
