@import "variables";

.errorMessage {
  display: flex;
  padding: 5px 10px;
  align-items: center;

  background-color: $errorBackgroundColor;
  border-radius: $normalBoxRadius;

  .icon {
    margin-right: 5px;
  }

  .message {
    color: $errorTextColor;
    font-size: $normalFontSize;
    line-height: $normalLineHeight;
  }
}
