@import "variables";

.newPasswordForm {
  width: 300px;
  margin: auto;

  .headline {
    margin-bottom: 20px;
  }

  .newPassword {
    margin-bottom: 20px;
  }

  .newPasswordConfirm {
    margin-bottom: 5px;
  }

  .notice {
    margin-bottom: 10px;
    .sentence {
      margin: 0;
      text-align: left;
      color: $textColor;
      font-size: $noticeFontSize;
      line-height: $normalLineHeight;
      font-family: $normalTextFont;
    }
  }
}
