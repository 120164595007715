@import "variables";

.textArea {
  box-sizing: border-box;
  width: 100%;
  min-width: 400px;
  margin: 0;
  padding: 5px 10px;

  height: 60px;

  border-width: 1px;
  border-style: solid;
  border-color: $formBorderColor;
  border-radius: $inputBorderRadius;

  box-shadow: none;
  outline: none;

  &:hover {
    border-color: $hoveredFormBorderColor;
  }

  &:focus {
    border-color: $focusedInputBorderColor;
  }

  &::selection {
    background-color: $inputSelectionColor;
  }
}

.textArea__error {
  @extend .textArea;
  border-color: $errorInputBorderColor;
}
