.grayBackPanel {
  width: 100%;
  .panel {
    padding: 20px;
    border-radius: 3px;
    background-color: #f4f4f4;

    margin-bottom: 10px;
  }

  .notice {
    color: #666666;
  }
}
