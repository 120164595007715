.panel {
  width: 100%;
  height: 100%;
  padding: 30px;

  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 5px;

  text-align: left;

  .title {
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
    padding-bottom: 10px;
  }

  .item:not(:last-child) {
    margin-bottom: 20px;
  }
}
