.grayBackDescriptionList {
  .title {
    margin-bottom: 10px;
  }

  .listArea {
    margin-left: 10px;

    .sectionTitle {
      margin-bottom: 5px;
    }
  }
}
