.elementModal {
  background-color: #fff;
  width: 600px;
  max-height: 550px;
  padding: 30px;

  text-align: left;

  border-radius: 3px;

  box-shadow: 0px 0px 10px #0000001a;

  .title {
    margin-bottom: 20px;
  }

  .introduction {
    margin-bottom: 10px;
    word-break: break-all;
  }

  .element {
    display: flex;
    max-height: 400px;
    justify-content: center;
    overflow-y: auto;
  }

  .note {
    margin-top: 5px;
    word-break: break-all;
    color: #666666;
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: right;

    .button:not(:last-child) {
      margin-right: 10px;
    }
  }
}
