.urlStyle {
  text-align: left;
  background-color: #f4f4f4;
  border-radius: 3px;
  padding: 5px 10px;
  .locations {
    .location {
      padding-left: 10px;
    }
  }
  .parameters {
    .parameter {
      padding-left: 10px;
    }
  }
}

.title {
  font-weight: bold;
}

.separator {
  padding: 5px 0px;
}

.segmentationRuleDescriptionBox {
  text-align: left;

  .currentPageBehavior {
    .url {
      margin-bottom: 10px;
    }
    .condition {
      border-radius: 3px;
      border: 1px solid #eee;
      padding: 10px;
    }
  }
  .sessionBehaviors {
    .behavior {
      border-radius: 3px;
      border: 1px solid #eee;
      padding: 10px;
      .url {
        margin-top: 5px;
      }
    }
  }
  .pastBehavior {
    .behavior {
      border-radius: 3px;
      border: 1px solid #eee;
      padding: 10px;
      .url {
        margin-top: 5px;
      }
    }
  }
  .customerAttributesInfo {
    .type {
      margin-bottom: 5px;
    }
    .attributes {
      .element {
        border-radius: 3px;
        border: 1px solid #eee;
        padding: 10px;
      }
    }
  }
}

.segmentationRuleConditionView {
  margin-bottom: 10px;
  .title {
    margin-bottom: 10px;
  }

  .description {
    padding-left: 10px;
  }
}
