@import "variables";

.ClientPageTemplate {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
  }

  .main {
    display: flex;
    width: 100%;

    .sideBar {
      min-width: 210px;
      margin-top: 20px;
    }

    .contents {
      margin-top: 28px;
      padding-right: 30px;
      overflow-wrap: break-word;
      min-width: 0;
      flex-grow: 1;
    }
  }

  .footer {
    padding: 50px 0;
  }
}
