.grantMessageTileLayout {
  height: 100%;
  display: flex;

  padding: 10px;
  background-color: #f4f4f4;

  .left {
    flex: 1 1 0;
    margin: 20px;
  }

  .right {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;

    .preview {
      flex: 1 1 0;
      margin-bottom: 2px;
      min-height: 250px;
    }
  }
}
