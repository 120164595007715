@import "variables";

.sideAreaTab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px 14px 20px;
  line-height: 21px;

  .name {
    font-weight: bold;
    font-size: $actionCreationSideAreaFontSize;
  }

  &:hover {
    background-color: $hoveredSideBarColor;
  }
}

.sideAreaTab__active {
  @extend .sideAreaTab;
  background-color: $hoveredSideBarColor;
}
