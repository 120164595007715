.segmentationRuleTreePageConditionForm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .pageArea {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 30px;
    .label {
      margin-right: 10px;
    }

    .selector {
      width: 100%;
      margin-right: 5px;
    }
  }

  .conditionArea {
    display: flex;
    justify-content: center;
    align-items: center;

    .label {
      margin-right: 10px;
    }

    .number {
      margin-right: 10px;
      width: 46px;
    }

    .compareTypeSelector {
      width: 100px;
    }
  }
}

.segmentationRuleTreeConditionForm {
  width: 100%;
  background-color: #f4f4f4;
  border-radius: 3px;
  padding: 20px;

  white-space: nowrap;

  .pageForms {
    width: 100%;
    .separator {
      margin: 20px 0;
    }

    .pageForm {
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      .deletePage {
        margin-left: 10px;
        width: 12px;
      }
    }
  }
  .addPage {
    margin-top: 10px;
    margin-left: 50px;
  }
}

.segmentationRuleTreeForm {
  width: 100%;

  .separator {
    margin: 20px 0;
  }

  .item {
    width: 100%;
    margin-bottom: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    .label {
      white-space: nowrap;
      margin-right: 10px;
    }

    .condition {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .deleteCondition {
        margin-left: 10px;
        width: 12px;
      }
    }
  }

  .addConditionButton {
    display: flex;
    justify-content: center;
  }
}
