@import "variables";

.input {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0px 0px 0px 3px;
  height: 30px;

  border: 1px solid #ccc;
  border-radius: 3px;

  box-shadow: none;
  outline: none;
  text-align: left;

  &:hover {
    border-color: #aaa;
  }

  &:focus {
    border-color: #ff7b2c;
  }

  &::selection {
    background-color: #ffebdc;
  }
}
