.segmentationRuleSettingBoard {
  .title {
    margin-bottom: 10px;
  }

  .form {
    margin-bottom: 5px;

    .item {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0px;
      }
    }

    .separator {
      margin: 5px 0px;
    }
  }
}
