.grantMessageListLayout {
  text-align: left;
  .panels {
    margin-bottom: 20px;

    .panelItem:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
