.ClientPageTemplateV2 {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
  }

  .body {
    width: 100%;
    height: 100%;
    min-height: 930px;
    display: flex;

    .sideBar {
      min-height: 100%;
    }

    .main {
      .contents {
        overflow-wrap: break-word;
        min-width: 0;
        margin-bottom: 50px;
        padding: 30px;
      }

      .footer {
        margin: 0px 0 10px;
      }
    }
  }
}
