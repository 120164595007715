.currentPageForm {
  display: flex;
  align-items: center;
  background-color: #eee;

  box-sizing: border-box;
  min-width: 790px;
  padding: 10px 15px;

  .behaviorSelector {
    margin-right: 10px;
    width: 200px;
  }

  .input {
    margin-right: 10px;
    width: 50px;
  }
}
