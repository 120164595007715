.reportView {
  .header {
    margin-bottom: 10px;
  }
  .report {
    margin-bottom: 10px;
  }
  .pageNation {
    margin-bottom: 10px;
  }
}
