@import "variables";

.requireIcon {
  width: 30px;
  height: 15px;

  text-align: center;

  font-size: $iconFontSize;
  line-height: 16px;
  color: $charactorIconFontColor;

  background-color: $charactorIconBackgroundColor;
  border-radius: 13px;
}
