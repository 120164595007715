.previewButton {
  border: 1px solid #ff7b2c;
  border-radius: 3px;

  color: #ff7b2c;
  padding: 2px 7px;

  display: flex;
  justify-content: center;
  align-items: center;

  white-space: nowrap;

  .icon {
    margin-right: 2px;
  }

  &:hover {
    cursor: pointer;
    background-color: #ffebdc;
  }
}
