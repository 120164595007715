.cell {
  box-sizing: border-box;
  height: 60px;
  border-right: 1px solid white;
  background-color: #ff7b2c;
  padding: 0;
  .item {
    height: 100%;
    padding: 0px 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    .label {
      margin-right: 10px;
    }
  }

  .nestedItem {
    height: 100%;
    display: flex;
    flex-direction: column;
    .head {
      display: flex;
      justify-content: left;
      align-items: center;
      text-align: left;
      padding: 0px 10px;
      flex: 1;
    }

    .nest {
      flex: 1;
      display: flex;
      justify-content: space-between;

      border-top: 1px solid white;

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        min-width: 70px;
        border-right: 1px solid white;

        .label {
          margin-right: 10px;
        }

        &:last-child {
          border-right: 0px solid transparent;
        }
      }
    }
  }
}

.fixedNo {
  @extend .cell;

  z-index: 10;
  position: sticky;
  width: 60px;
  left: 0;

  border-right: 1px solid white;

  // &:before {
  //   content: "";
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  //   width: 100%;
  //   height: 100%;
  //   border-right: 1px solid white;
  // }
}

.fixedName {
  @extend .cell;

  z-index: 10;
  box-shadow: 10px 10px;
  position: sticky;
  min-width: 200px;
  left: 58px;
  &:before {
    content: "";
    position: absolute;
    top: 0px;
    right: -20px;
    width: 20px;
    height: 100%;
    border-left: 1px solid white;
    box-shadow: inset 5px 0px 8px #e86f27;
  }
}

.sortIcon {
  &:hover {
    cursor: pointer;
  }
}
.sortIconRotated {
  transform: rotate(180deg);
  &:hover {
    cursor: pointer;
  }
}
