.gradationColorCircle {
  width: 30px;
  height: 30px;

  .circle {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;

    &:hover {
      transform: scale(1.2);
    }
  }

  .check {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
