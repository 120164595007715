@import "variables";

.gradationColorPickForm {
  display: flex;
  align-items: center;

  .name {
    font-size: $normalFontSize;
    line-height: $formNameLineHeight;
    margin-right: 10px;
  }
}
