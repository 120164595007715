@import "variables";

.actionTable {
  .table {
    border-collapse: separate;
    border-spacing: 0;
    margin: 0;
    margin-bottom: 10px;
    width: 99.9999%;

    .tbody {
      font-size: $normalFontSize;
      line-height: $tableHeaderLineHeight;
    }
  }

  .pagination {
    margin-top: 20px;
  }
}
