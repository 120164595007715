@import "variables";

.actionName {
  display: flex;
  justify-content: space-between;
  min-height: 20px;
  box-sizing: border-box;

  .name {
    color: $textColor;
    background-color: transparent;
    border: none;
    padding: 0;
    text-align: left;
    text-decoration: none;
    width: 100%;

    &:hover {
      cursor: pointer;
      color: $linkColor;
    }

    &:active,
    &:focus {
      border: none;
      outline: none;
    }
  }

  .operation {
    display: flex;
    justify-content: center;
    width: 80px;
  }
}
