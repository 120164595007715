.sessionForm {
  box-sizing: border-box;
  min-width: 790px;
  padding: 10px 15px;
  background-color: #eeeeee;

  .behavior {
    display: flex;
    align-items: center;
    .select {
      margin-right: 10px;
      width: 200px;
    }
    .input {
      margin-right: 10px;
      width: 50px;
    }
    .unit {
      margin-right: 10px;
    }
    .compareType {
      width: 100px;
    }
  }

  .url {
    margin-top: 10px;
  }
}
