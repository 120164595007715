@import "variables";

.sentence {
  margin: 0;
  padding: 0;
  background-color: transparent;
  font-size: $normalFontSize;
  line-height: $normalLineHeight;
  font-family: $normalTextFont;
  color: $textColor;
}
