@import "variables";

.contentsEditPhaseFormMainEditArea {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 728px;

  .header {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    border-bottom: 1px solid $tableBorderColor;
    box-shadow: 0px 10px 10px -5px #eeeeee;

    .elem {
      flex: 1;
    }
  }

  .form {
    margin-right: 10px;
    overflow-y: scroll;
    flex: 1;
  }
}
