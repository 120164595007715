.container {
  background-color: #ffffff;
}
.gnav {
  height: 30px;
  margin: 0 auto;
  list-style: none;
  :hover {
    cursor: pointer;
  }
}
ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
/*全てのリスト・リンク共通*/
.gnav li {
  list-style: none;
  position: relative;
}
.gnav li div {
  display: block;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
}
.layerTop {
  border: 1px solid #cccccc;
  border-radius: 3px;
  list-style: none;
  position: relative;
  display: block;
  height: 30px;
  line-height: 30px;
  text-decoration: none;
  text-align: left;
  padding-left: 10px;
  width: 280px;
}
.layerSecondTop {
  border: none;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: white;
  text-align: left;
  width: 280px;
  position: static;
  &:hover {
    background-color: #ffebdc;
  }
}
.layerSecond {
  border: none;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  background-color: white;
  text-align: left;
  width: 280px;
  &:hover {
    background-color: #ffebdc;
  }
}
.layerSecondLast {
  border: none;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  background-color: white;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  text-align: left;
  width: 280px;
}

.layerChildren {
  border: none;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  background-color: white;
  text-align: left;
  width: 110px;
  &:hover {
    background-color: #ffebdc;
  }
  &:first-child {
    border-top: 1px solid #cccccc;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  &:last-child {
    border-bottom: 1px solid #cccccc;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

/*子階層以降共通*/
.gnav li li {
  visibility: hidden;
  height: 0;
}
.gnav li:hover > ul > li {
  height: 30px;
  visibility: visible;
}
.gnav li ul li ul {
  right: 110px;
  position: relative;
  top: -30px;
}

.buttonRight {
  content: "";
  right: 1rem;
  position: absolute;
  transform: translateY(-33px);
}
.hoverIcon {
  content: "";
  right: 1rem;
  position: absolute;
  top: 1em;
  transform: translateY(-50%);
}
