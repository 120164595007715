@import "variables";

.errorMessage {
  color: $errorTextColor;
  width: 300px;
  display: inline-block;
  text-align: left;
  line-height: 1.2;

  .icon {
    vertical-align: middle;
    margin-right: 5px;
  }

  .text {
    vertical-align: middle;
    font-size: $normalFontSize;
    font-family: $normalTextFont;
  }
}
