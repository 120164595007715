.miniPanel {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 15px;

  display: flex;
  flex-direction: column;

  .title {
    margin-bottom: 5px;
  }

  .main {
    flex: 1 1 0;
  }
}
