@import "variables";

.loadingIcon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    box-sizing: border-box;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow: 0 -10px 0 #ff523c, 7px -7px 0 #ff7b2cdd, 10px 0 0 #ff7b2ccc,
      7px 7px 0 #ff7b2cbb, 0 10px 0 #ff7b2caa, -7px 7px 0 #ff7b2c99,
      -10px 0 0 #ff7b2c66, -7px -7px 0 #ff7b2c33;
    animation: rotate 1s steps(8) 0s infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
