.customerAttributeForm {
  box-sizing: border-box;
  display: flex;
  background-color: #eee;
  min-width: 790px;
  padding: 10px 15px;

  .keySelector {
    width: 200px;
    margin-right: 10px;
  }
  .dataSelector {
    margin-right: 10px;
    width: 100px;
  }
  .input {
    margin-right: 10px;
    min-width: 300px;
  }
  .compareType {
    width: 130px;
  }
}
