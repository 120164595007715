@import "variables";

.presetFormatSelectButton {
  width: 230px;
  height: 120px;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $imageTextButtonColor;

  border-width: 2px;
  border-color: transparent;
  border-radius: $buttonBorderRadius;
  border-style: solid;

  font-size: $normalFontSize;
  line-height: $normalLineHeight;

  &:hover {
    border-color: $hoveredImageTextButtonBorderColor;
  }

  .image {
    margin-bottom: 10px;
    height: 47px;

    .img {
      height: 47px;
    }
  }

  .text {
    font-size: $presetFormatSelectBoxFontSize;
    line-height: $presetFormatSelectBoxLineHeight;
  }
}
