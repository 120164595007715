.segmentationRuleList {
  width: 1010px;

  .controllers {
    display: flex;
    align-items: center;

    .item:not(:last-child) {
      margin-right: 15px;
    }
  }
}
