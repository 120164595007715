@import "variables";

.eventText {
  color: $linkColor;
  font-size: $normalFontSize;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
}

.eventText__unavailable {
  @extend .eventText;
  filter: opacity(30%);

  &:hover {
    cursor: default;
  }
}
