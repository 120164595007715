@import "variables";

.parameters {
  .forms {
    margin-bottom: 5px;
    .form {
      display: flex;
      align-items: center;

      .name {
        margin-right: 5px;
        margin-left: 10px;
        word-break: keep-all;
      }

      .empty {
        padding-left: 80px;
      }

      .select {
        margin-right: 5px;
        width: 110px;
      }

      .input {
        min-width: 320px;
        margin-right: 10px;
      }
    }
  }

  .separator {
    margin: 5px 0px;
    padding-left: 80px;
  }

  .add {
    padding-left: 80px;
  }
}
