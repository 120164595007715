@import "variables";

.reportTable {
  box-sizing: border-box;
  width: 100%;
  border: 0px solid transparent;
  border-collapse: collapse;
  .header {
    color: white;
    font-size: 13px;
    word-break: keep-all;
    height: 63px;
  }

  .row {
    border-left: none;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    background-color: white;
  }
}
