// Master
$masterWidth: 1280px;
$masterHeight: 720px;
$masterBackgroundColor: #f4f4f4;

$textColor: #000;
$disableTextColor: rgba(0, 0, 0, 0.3);
$linkColor: #ff5c38;
$activeLinkColor: #aaa;
$errorTextColor: #dc1340;
$errorBackgroundColor: #f9eef1;
$noticeTextColor: #aaa;
$inactiveTextColor: #ccc;
$emphasisColor: #ff7b2c;

$normalBoxRadius: 3px;

///// Scroll bar /////
$scrollBarThumbColor: #ddd;

///// Table /////
$tableBackgroundColor: #fff7f1;
$hoveredTableBackgroundColor: #ffebdc;
$tableOddBackgroundColor: #fff7f1;
$tableEvenBackgroundColor: #fff;
$tableBorderColor: #ddd;
$tableBorderRadius: 3px;

///// Form /////
$formBorderColor: #ccc;
$hoveredFormBorderColor: #aaa;
$placeholderColor: #ccc;

///// Input /////
$inputBackgroundColor: #f4f4f4;
$inputBorderRadius: 3px;
$focusedInputBorderColor: #ff7b2c;
$inputSelectionColor: #ffebdc;
$errorInputBorderColor: #dc1340;

///// Form Header /////
$formHeaderColor: #f4f4f4;

///// Form Footer /////
$formFooterColor: #f4f4f4;

///// Action creation side area /////
$sideBarColor: #f4f4f4;
$hoveredSideBarColor: #ffffff;

///// DATE RANGE PICKER /////
$dateRangePickerBorderColor: #ccc;
$dateRangePickerInRangeDateColor: #ffebdc;
$dateRangePickerSelectedDateBackgroundColor: #ff7b2c;
$dateRangePickerSelectedDateTextColor: #fff;
$dateRangePickerSelectedDateTodayTextColor: #ff7b2c;
$dateRangePickerDisabledDateTextColor: #aaa;
$activeDateRangePickerBorderColor: #aaa;

///// BUTTONS /////
$buttonBorderColor: #ddd;
$buttonBorderRadius: 3px;

// Positive button
$positiveButtonColor: #ff7b2c;
$hoveredPositiveButtonColor: #ff523c;
$positiveButtonTextColor: #fff;

// Negative button
$negativeButtonColor: #fff;
$negativeButtonTextColor: #000000;
$hoveredNegativeButtonColor: #f9f9f9;

// Neutral button
$neutralButtonColor: #aaaaaa;
$neutralButtonTextColor: #ffffff;
$hoveredNeutralButtonColor: #f9f9f9;

// Sidebar button
$activeSideBarButtonColor: #ffebdc;

// image text button
$hoveredImageTextButtonBorderColor: #ffbd95;
$imageTextButtonColor: #f4f4f4;

// tab button
$focusedCategoryTabBorderColor: #ff7b2c;

// Pagination
$paginationTextColor: #ff5c38;
$activePaginationButtonColor: #ff7b2c;
$hoveredPaginationButtonColor: #fff7f1;
$activePaginationTextColor: #fff;

// Selectbox
$selectBoxColor: #fff;
$hoveredItemColor: #fff7f1;

// DescriptionBox
$descriptionBoxBackgroundColor: #fff;
$descriptionBoxBorderColor: #ccc;
$descriptionBoxBorderRadius: 3px;

// Calender
$calenderBackgroundColor: #fff;

// Pagination
$paginationBoxSize: 24px;

///// Icon /////
$charactorIconFontColor: #fff;
$charactorIconBackgroundColor: #ff7b2c;

///// Image uploader /////
$imageUploaderTextColor: #aaaaaa;
$imageUploaderBackgroundColor: #f4f4f4;
$imageUploaderBorderColor: #cccccc;

///// Check box /////
$checkboxButtonColor: #ff7b2c;
$checkboxBorderColor: #cccccc;
$checkboxBorderRadius: 3px;

///// Unavailable board /////
$boardBackgroundColor: #eeeeee;

///// Precautionary icon /////
$precautionaryIconColor: #ffebdc;

///// Radio button /////
$radioButtonBorderColor: #cccccc;
$activeRadioButtonBorderColor: #ff7b2c;

///// FONTS /////
// Font
$normalTextFont: "Meiryo", sans-serif;

// Font size
$headlineFontSize: 16px;
$siteNaviFontSize: 14px;
$normalFontSize: 13px;
$footerFontSize: 12px;
$noticeFontSize: 11px;
$paginationFontSize: 24px;
$actionCreationSideAreaFontSize: 14px;
$iconFontSize: 10px;
$presetFormatSelectBoxFontSize: 14px;

// Line height
$normalLineHeight: 20px;
$noticeLineHeight: 17px;
$footerLineHeight: 18px;
$tableHeaderLineHeight: 20px;
$headlineLineHeight: 24px;
$paginationLineHeight: 24px;
$ActionCreationSideAreaLineHeight: 16px;
$formNameLineHeight: 14px;
$precautionaryLineHeight: 14px;
$presetFormatSelectBoxLineHeight: 18px;

///// Z index /////
$notificateModalZIndex: 100;
