.segmentationRuleEditPanel {
  width: 1010px;

  .errorMessages {
    margin-bottom: 15px;
  }

  .panel {
    margin-bottom: 20px;

    .name {
      width: 928px;
    }

    .duration {
      display: flex;
      .label {
        margin-right: 15px;
      }
      .form {
        .toggle {
          margin-bottom: 10px;
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
  }
}
