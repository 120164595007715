.initializePageTemplate {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
  }

  .main {
    width: 100%;
    height: 100%;
    min-height: 930px;
    .contents {
      overflow-wrap: break-word;
      min-width: 0;
      margin-bottom: 50px;
      margin: 30px 135px;
    }

    .footer {
      margin: 0px 0 10px;
    }
  }
}
