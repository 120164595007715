@import "variables";

.detailTable {
  .table {
    width: 100%;

    border: 1px solid $tableBorderColor;
    border-spacing: 0px;
    border-radius: 3px;

    font-size: $normalFontSize;
    line-height: $tableHeaderLineHeight;
    font-family: $normalTextFont;
    text-align: left;

    .tr {
      &:nth-child(odd) {
        background-color: $tableEvenBackgroundColor;
      }

      &:nth-child(even) {
        background-color: $tableOddBackgroundColor;
      }

      &:first-child {
        .th {
          border-top-left-radius: 3px;
        }

        .td {
          border-top-right-radius: 3px;
        }
      }

      &:last-child {
        .th {
          border-bottom-left-radius: 3px;
        }

        .td {
          border-bottom-right-radius: 3px;
        }
      }
    }

    .th {
      min-width: 120px;
      padding: 10px 15px;
      text-align: left;
      font-weight: bold;
      vertical-align: top;
    }

    .td {
      padding: 10px 15px 10px 0px;
    }
  }
}
