.contentsEditPhaseFormMainEditArea {
  display: flex;
  flex-direction: row;
  .header {
    font-weight: 1000;
    width: 100%;
    box-shadow: 0px 10px 10px -5px #eeeeee;
    display: inline-flex;
    &:hover {
      cursor: pointer;
    }
  }
  .tab {
    width: 100%;
    text-align: center;
    display: inline-block;
  }
}

.accessStatisticsBackGround {
  background-color: #fbfbfb;
  overflow-y: scroll;
  min-height: 330px;
  max-height: 330px;
  padding-top: 20px;
}
