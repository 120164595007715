.titleMainLayout {
  width: 1010px;

  .header {
    margin-bottom: 10px;
  }

  .title {
    margin-bottom: 15px;
    text-align: left;
  }

  .main {
  }
}
