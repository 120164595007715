@import "variables";

.errorMessage {
  color: $errorTextColor;

  .icon {
    vertical-align: middle;
    margin-right: 5px;
  }

  .text {
    vertical-align: middle;
    font-size: $normalFontSize;
    font-family: $normalTextFont;
  }
}
