@import "variables";

.segmentationRuleDescriptionView {
  background-color: transparent;
  border: 1px solid #eee;
  border-radius: 3px;

  .name {
    padding: 5px 20px;
    background-color: #fff7f1;
    font-weight: bold;
  }

  .description {
    padding: 10px 20px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

.segmentationRuleDescriptionList {
  .separator {
    margin: 5px 0px;
  }
}
