.andSeparator {
  display: flex;
  align-items: center;
  width: 100%;

  .bar {
    border-top: 1px solid #ddd;
    flex: 1;
  }

  .text {
    color: #ddd;
    margin: 0px 10px;
  }
}
