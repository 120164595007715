@import "variables";

.locationsFormView {
  padding: 10px 15px;
  display: flex;
  border: 1px solid $formBorderColor;

  .urlInputArea {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .urlInput {
      margin-right: 10px;
      width: 500px;

      .locations {
        display: flex;
        margin-bottom: 10px;

        .location {
          flex-grow: 1;
        }
        .delete {
          margin: 5px;
        }
      }
    }
  }

  .separator {
    margin-bottom: 5px;
  }
}
