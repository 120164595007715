.marginInputElement {
  display: flex;
  justify-content: center;
  align-items: center;

  .position {
    margin-right: 5px;
  }

  .input {
    width: 46px;
  }

  .unit {
    margin-left: 5px;
  }
}

.marginSettingForm {
  width: auto;
  display: inline-flex;
  justify-content: left;
  align-items: center;
  background-color: #f4f4f4;
  padding: 15px 10px;

  line-height: 20px;

  .title {
    margin-right: 5px;
    white-space: nowrap;
  }

  .toolTip {
    margin-right: 20px;
  }

  .topInput {
    margin-right: 10px;
  }
}
