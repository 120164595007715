.currentPV {
  border-color: aquamarine;
}
.titleText {
  background-color: #fff7f1;
  padding: 15px;
  border: 1px solid #dddddd;
  border-right: none;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  font-weight: 1000;
  display: inline-flex;
}
.titleValue {
  display: inline-table;
  text-align: right;
  border-left: none;
  padding: 15px;
  border: 1px solid #dddddd;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  min-width: 100px;
}
.term {
  display: inline-flex;
  padding: 15px;
  font-size: 10px;
}
