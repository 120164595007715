.separator {
  display: flex;
  justify-content: center;
  align-items: center;

  .operator {
    margin: 0 10px;
  }

  .separateLine {
    flex: 1;
    border-top: 1px solid #aaaaaa;
    height: 0;
  }
}
