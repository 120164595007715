.segmentationRuleCustomerAttributeForm {
  .type {
    margin-bottom: 10px;
    width: 200px;
  }
  .attributes {
    .separator {
      margin: 5px 0px;
    }
  }
}
