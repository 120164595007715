.message {
  text-align: left;
  padding: 0px 0px 15px 0px;
}

.table {
}

.goBackButton {
  text-align: right;
  padding: 26px 0px 0px 0px;
}
