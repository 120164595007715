@import "variables";

.neutralButton {
  background-color: $neutralButtonColor;
  border: none;
  border-radius: $buttonBorderRadius;
  opacity: 1;
  height: 30px;
  padding: 0px 10px;

  font: normal normal normal 13px/16px $normalTextFont;
  color: $neutralButtonTextColor;

  cursor: pointer;
  white-space: nowrap;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }
}
