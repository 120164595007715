@import "variables";

.expirationPeriodDatePicker {
  display: flex;
  align-items: center;

  .title {
    margin-right: 10px;
  }

  .date {
    margin-right: 10px;
  }
}
