@import "variables";

.SideBar {
  min-width: 200px;

  .tab {
    display: table;
    height: 40px;
    width: 190px;

    margin-bottom: 10px;

    border-radius: 0px 50px 50px 0px;
    text-align: left;

    .link {
      display: table-cell;
      vertical-align: middle;
      padding-left: 30px;
      width: 100%;
      height: 100%;
      font: normal normal normal 14px/16px $normalTextFont;
      letter-spacing: 0px;
      color: $textColor;
      text-decoration: none;
    }

    &:hover {
      background: $activeSideBarButtonColor 0% 0% no-repeat padding-box;
    }
  }

  .active {
    background: $activeSideBarButtonColor 0% 0% no-repeat padding-box;
  }
}
