.grayBackTitleForm {
  margin-bottom: 40px;
  .title {
    margin-bottom: 20px;
  }

  .elements {
    .element:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
