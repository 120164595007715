.pushNotificationLists {
  width: 1010px;
  .list {
    padding: 30px;
    background-color: #ffffff;

    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
