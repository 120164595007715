@import "variables";

.paginateArea {
  max-width: 320px;
  margin: auto;
}

.paginate {
  display: flex;

  margin: 0;
  padding: 0;
  padding-inline-start: 0;
  justify-content: center;

  list-style: none;

  background-color: transparent;
  color: $paginationTextColor;
  font-size: $normalFontSize;

  vertical-align: middle;
  box-sizing: border-box;

  .activePage {
    background-color: $activePaginationButtonColor;
    color: $activePaginationTextColor;
    pointer-events: none;
  }

  .page {
    width: $paginationBoxSize;
    height: $paginationBoxSize;
    border-radius: 3px;
    margin: 0 2px;
    line-height: $paginationLineHeight;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: $hoveredPaginationButtonColor;
      color: $paginationTextColor;
    }

    a {
      display: block;
      width: $paginationBoxSize;
      height: $paginationBoxSize;
    }
  }

  .arrow {
    width: $paginationBoxSize;
    height: $paginationBoxSize;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  .disabled {
    width: $paginationBoxSize;
    height: $paginationBoxSize;
    opacity: 0.3;
    pointer-events: none;
  }
}
