.segmentationRuleCreationCurrentPageForm {
  .url {
    margin-bottom: 5px;
  }

  .descriptions {
    margin-bottom: 10px;
  }

  .forms {
    .separator {
      margin: 5px 0px;
    }
  }
}
