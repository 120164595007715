@import "variables";

.overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(102, 102, 102, 0.3);
}

.content {
  position: absolute;
  inset: 0px;
  width: 900px;
  height: 550px;
  margin: 7.8% auto 0;
  padding: 30px 10px 30px 30px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px #0000001a;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
