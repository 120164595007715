@import "variables";

.AccessStatisticsButton {
  background: #fff7f1;
  border: 1px solid $positiveButtonColor;
  border-radius: 3px;
  opacity: 1;
  height: 30px;
  padding: 0px 10px;
  font-family: $normalTextFont;
  font-size: $normalFontSize;

  cursor: pointer;
  white-space: nowrap;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    background-color: #ffebdc;
  }

  button + & {
    margin-left: 10px;
  }
}

.pvHeader {
  display: inline-flex;
  font-weight: 1000;
  padding-left: 5px;
  padding-right: 10px;
}

.data {
  display: inline-flex;
  padding-right: 5px;
}

.separator {
  display: inline-flex;
  padding-right: 5px;
  color: $positiveButtonColor;
}
.icon {
  display: inline-flex;
  padding-left: 5px;
  padding-right: 5px;
  transform: scale(1, 1) translateY(1px);
}
