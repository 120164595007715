.loadingPageView {
  width: 100%;
  height: 100%;

  animation: fadeIn 1s ease 0s 1 normal;

  .elementsArea {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      margin-bottom: 25px;
    }

    .greeting {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      text-align: center;

      margin-bottom: 50px;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
