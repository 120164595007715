@import "variables";

.authFormV2 {
  .input {
    margin-bottom: 10px;
  }

  .submit {
    padding-top: 10px;
  }
}
