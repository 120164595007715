.orangeHeaderTableFrame {
  width: 100%;
  display: block;
  .orangeHeaderTable {
    width: 100%;
    box-sizing: border-box;
    border-collapse: collapse;
    border: 1px solid #dddddd;

    text-align: left;

    .header {
      background-color: #ff7b2c;
      color: #ffffff;

      th {
        padding: 10px 15px;
        white-space: nowrap;
      }

      th:not(:last-child) {
        border-right: 1px solid #ffffff;
      }
    }

    .body {
      td {
        box-sizing: border-box;
        padding: 10px 15px;
        height: 50px;
        min-height: 50px;
      }
      td:not(:last-child) {
        border-right: 1px solid #dddddd;
      }

      tr:nth-child(even) {
        background-color: #fff7f1;
      }
      tr:nth-child(odd) {
        background-color: #ffffff;
      }
    }
  }
}
