.DeliveryReportPageForm {
  width: 1010px;
  .trackingRangeForm {
    display: flex;
    align-items: center;
    .form {
      width: 50px;
      margin-right: 10px;
    }
  }

  .submit {
    width: 70px;
  }
}
