@import "variables";

.formTitle {
  display: flex;
  align-items: center;

  .title {
    font-size: $normalFontSize;
    font-weight: bold;
    line-height: $formNameLineHeight;
    font-family: $normalTextFont;
  }

  .additional {
    margin-left: 10px;
  }
}
