.layout {
  text-align: left;

  .title {
    margin-bottom: 5px;
  }

  .form {
    width: 400px;
  }
}
