@import "variables";

.PositiveButton {
  background: $positiveButtonColor 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 3px;
  opacity: 1;
  height: 30px;
  padding: 0px 10px;

  font-family: $normalTextFont;
  font-size: $normalFontSize;
  color: $positiveButtonTextColor;

  cursor: pointer;
  white-space: nowrap;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    background-color: $hoveredPositiveButtonColor;
  }

  button + & {
    margin-left: 10px;
  }
}
