.treeNodeUrlSettingBox {
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 3px;

  .locationArea {
    .label {
    }
    .value {
      margin-left: 15px;
    }
  }

  .parameterArea {
    margin-top: 10px;

    .andSeparator {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.treeNodeBox {
  background-color: #ffffff;

  .andSeparator {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .description {
    margin-bottom: 5px;
  }
  .urlSettingBox {
    margin-left: 15px;
  }
}

.durationSetting {
  display: flex;

  .head {
    flex: 0 0 50px;
  }
}

.conditionSetting {
  display: flex;
  align-items: flex-start;

  .head {
    flex: 0 0 50px;
  }

  .body {
    border-radius: 3px;
    border: 1px solid #dddddd;
    padding: 15px;
  }
}

.segmentationRuleTreeTable {
  word-break: break-all;

  .durationArea {
    margin-bottom: 15px;
  }

  .separator {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 50px;
  }
}
