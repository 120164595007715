.layout {
  .topic {
    margin-bottom: 10px;
  }

  .headline {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .title {
      margin-right: 10px;
    }
  }

  .errorMessage {
    margin-bottom: 15px;
  }

  .stepBar {
    margin-bottom: 15px;
  }
}
