.downloadButton {
  padding: 3px 7px;
  border: 1px solid #ff7b2c;
  border-radius: 3px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #fff7f1;
  }

  display: flex;
  justify-self: center;
  align-items: center;

  .icon {
    margin-right: 2px;
  }

  .text {
    color: #ff7b2c;
    font-size: 8px;
    line-height: 1;
    vertical-align: bottom;
  }
}
