.tableTab {
  // NOTE: shape
  min-width: 130px;
  min-height: 44px;

  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

  padding: 0 15px;

  // NOTE: character place adjust
  display: flex;
  justify-content: center;
  align-items: center;

  // NOTE: misc
  cursor: pointer;

  // NOTE: color
  background-color: #eeeeee;

  &:hover {
    background-color: #ffffff;
  }

  &__active {
    @extend .tableTab;

    background-color: #ffffff;
  }
}
