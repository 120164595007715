@import "variables";

.stepBar {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $formHeaderColor;

  cursor: default;

  .item {
    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    height: 40px;
    margin-right: 60px;
    padding: 10px 10px;

    color: $inactiveTextColor;

    .step {
      font-size: $noticeFontSize;
      margin-right: 5px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }

  .item__active {
    @extend .item;
    color: $textColor;
    font-weight: bold;

    border-top: 3px solid transparent;
    border-bottom: 3px solid $emphasisColor;

    .step {
      color: $emphasisColor;
    }
  }
}
