.breadCrumbs {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: left;
  .item {
    display: inline;
    .link {
      color: #ff5c38;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
