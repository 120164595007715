.dummyImage {
  background-color: #d9d9d9;
  vertical-align: middle;
  width: 70px;
  height: 70px;

  display: flex;
  justify-content: center;
  align-items: center;

  .text {
    color: #ffffff;
    font-weight: bold;
    font-family: helvetica;
  }
}

.pushNotificationContentsPreviewer {
  box-sizing: border-box;
  width: 420px;

  display: flex;
  align-items: flex-start;

  padding: 15px;

  border: 1px solid #8d8d8d;
  border-radius: 3px;

  .imageFrame {
    width: 70px;
    height: 70px;
    min-width: 70px;

    overflow: hidden;

    .image {
      width: 70px;
      height: 70px;
      object-fit: cover;
      object-position: left top;
    }

    margin-right: 20px;
  }

  .textArea {
    word-break: break-all;

    .title {
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: bold;
    }

    .body {
      font-size: 13px;
      font-weight: normal;
    }
  }
}
