.publicStatus {
  display: flex;
  align-items: baseline;

  .text {
    margin-right: 3px;
  }

  .status {
    color: #fff;
    border-radius: 13px;
    padding: 3px 5px 2px;

    &__positive {
      @extend .status;
      background-color: #ff7b2c;
    }

    &__negative {
      @extend .status;
      background-color: #aaaaaa;
    }
  }
}
