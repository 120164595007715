.ContentsLayoutFormView {
  padding: 20px 30px;

  .contentsType {
    margin-bottom: 20px;
  }

  .contentsPlace {
    margin-bottom: 20px;
  }

  .formatPattern {
  }
}
