@import "variables";

.URLForm {
  width: 586px;
  .location {
    width: 520px;
    margin-bottom: 5px;
  }

  .parameters {
    background-color: $inputBackgroundColor;
    padding: 10px 15px;
    display: flex;

    .header {
      display: flex;

      padding-top: 5px;
      margin-right: 10px;

      .title {
        margin-right: 5px;
      }

      .toolTip {
        padding-top: 2px;
      }
    }

    .main {
      .form {
        display: flex;

        .parameter {
          margin-right: 10px;

          .param {
            margin-bottom: 5px;
          }

          .separator {
            margin-bottom: 5px;
          }
        }

        .deleteButton {
          display: flex;
          align-items: flex-end;
          padding-bottom: 14px;
          vertical-align: bottom;
        }
      }
    }
  }
}
