@import "variables";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  width: 100%;

  .headerRight {
    display: flex;
    align-items: center;

    .title {
      margin-right: 10px;
    }

    .createButton {
      margin-right: 10px;
    }
  }

  .headerLeft {
    padding-right: 0px;
  }
}
