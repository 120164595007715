.period {
  vertical-align: middle;
  display: flex;
  align-items: center;
  .picker {
    text-align: center;
    width: 67px;
    height: 30px;
  }
  .text {
    text-align: center;
    width: 13px;
    height: 20px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
