@import "variables";

.imageTextTile {
  width: 180px;
  padding-top: 15px;
  padding-bottom: 10px;
  box-sizing: border-box;

  background-color: $imageTextButtonColor;

  border-width: 2px;
  border-color: transparent;
  border-radius: $buttonBorderRadius;
  border-style: solid;

  font-size: $normalFontSize;

  &:hover {
    border-color: $hoveredImageTextButtonBorderColor;
  }

  .image {
    height: 80px;
    margin-bottom: 10px;
  }
}

.imageTextTile__active {
  @extend .imageTextTile;
  border-color: $hoveredImageTextButtonBorderColor;
}
