@import "variables";

.tr {
  font-size: $normalFontSize;
  line-height: $tableHeaderLineHeight;
  font-family: $normalTextFont;
  //padding-right: 30px;

  &:nth-child(odd) {
    background-color: $tableEvenBackgroundColor;
  }

  &:nth-child(even) {
    background-color: $tableOddBackgroundColor;
  }

  &:hover {
    background-color: $hoveredTableBackgroundColor;
  }

  .td {
    text-align: left;
    vertical-align: middle;
    padding: 10px 15px;
    border-left: 1px solid $tableBorderColor;

    &:last-child {
      //padding-right: 30px;
      border-right: 1px solid $tableBorderColor;
    }
  }

  &:first-child {
    .td {
      border-top: 1px solid $tableBorderColor;
      &:first-child {
        border-radius: $tableBorderRadius 0 0 0;
      }
      &:last-child {
        border-radius: 0 $tableBorderRadius 0 0;
      }
    }
  }
  &:last-child {
    .td {
      border-bottom: 1px solid $tableBorderColor;
      &:first-child {
        border-radius: 0 0 0 $tableBorderRadius;
      }
      &:last-child {
        border-radius: 0 0 $tableBorderRadius 0;
      }
    }
  }

  .td__segmentation_rule_name {
    @extend .td;
    min-width: 300px;
    // padding: 0px 15px;
  }

  .td_right {
    @extend .td;
    text-align: right;
    max-width: 150px;

    .calculating {
      color: $disableTextColor;
    }
  }
}
