.segmentationRulesFormView {
  .main {
    display: flex;

    .forms {
      margin-right: 10px;
      margin-bottom: 0;
      width: 400px;

      .form {
        margin-bottom: 5px;
      }

      .andText {
        margin-bottom: 5px;
      }
    }

    .delete {
      display: flex;
      align-items: flex-end;
      width: 10px;
      padding-bottom: 14px;
      vertical-align: bottom;
    }
  }
}
