.notificationModal {
  background-color: #fff;
  width: 600px;
  padding: 30px;

  border-radius: 3px;

  box-shadow: 0px 0px 10px #0000001a;

  text-align: left;

  .title {
    margin-bottom: 10px;
  }

  .description {
    // width: 100%;
    margin-bottom: 20px;
  }

  .buttons {
    display: flex;
    justify-content: right;

    .button:not(:last-child) {
      margin-right: 10px;
    }
  }
}
