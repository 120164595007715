.orangeButton {
  // shape
  height: 30px;
  padding: 5px 10px;
  border-radius: 3px;

  // color
  color: #ffffff;
  background-color: #ff7b2c;

  // decoration
  text-decoration: none;
  border: none;

  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: #ff5c38;
  }
}
