@import "variables";

.form {
  .main {
    background-color: white;
    border: 1px solid $tableBorderColor;
    border-radius: $tableBorderRadius;
    padding: 30px;

    .layout {
      margin-bottom: 10px;
      .item {
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0px;
    border-bottom-left-radius: $tableBorderRadius;
    border-bottom-right-radius: $tableBorderRadius;

    .button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
