.imageUploaderConfirmButtons {
  display: flex;
  align-items: center;

  .fileName {
    max-width: 300px;
  }

  .element:not(:last-child) {
    margin-right: 10px;
  }
}
