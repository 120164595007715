.colorTypeSelector {
  display: flex;

  .cell {
    padding: 3px 10px;
    color: #000;
    background-color: #f4f4f4;
    word-break: keep-all;
    user-select: none;

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  .cell__active {
    @extend .cell;
    color: #fff;
    background-color: #ff7b2c;
  }
}
