@import "variables";

.modeSelectButton {
  width: 270px;
  height: 200px;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $imageTextButtonColor;

  border-width: 2px;
  border-color: transparent;
  border-radius: $buttonBorderRadius;
  border-style: solid;

  font-weight: bold;
  font-size: $headlineFontSize;

  &:hover {
    border-color: $hoveredImageTextButtonBorderColor;
  }

  .image {
    margin-bottom: 24px;
  }
}
