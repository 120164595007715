@import "variables";

.categoryHeader {
  min-height: 38px;
  font-size: $normalFontSize;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 0px;
  flex-wrap: wrap;

  .additionalLabel {
    margin-left: 10px;
  }

  &:hover {
    padding-bottom: 0px;
    border-bottom: 3px solid $focusedCategoryTabBorderColor;
  }
}

.categoryHeader__active {
  @extend .categoryHeader;
  padding-bottom: 0px;
  border-bottom: 3px solid $focusedCategoryTabBorderColor;
}
