.panelHorizontalLayout {
  display: flex;
  align-items: stretch;

  .item {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .item:not(:last-child) {
    margin-right: 30px;
  }
}
