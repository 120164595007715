.clickableAreaText {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;

  &:hover {
    color: #ff5c38;
    cursor: pointer;
  }
}
