@import "variables";

.timePickerContainer {
  box-sizing: border-box;
  background-color: white;
  border: 1px solid $dateRangePickerBorderColor;
  border-radius: $buttonBorderRadius;
  padding: 5px 0;

  .main {
    border: none;
    background-color: white;
  }
}
