@import "variables";

.inactiveCheckBox {
  box-sizing: border-box;
  width: 15px;
  height: 15px;
  border: 1px solid $checkboxBorderColor;
  border-radius: $checkboxBorderRadius;

  background-color: #ffffff;
}
