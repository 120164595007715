.cell {
  border-right: 1px solid #cccccc;
  .item {
    padding: 10px 16px;
  }

  .nest {
    display: flex;

    .nestedItem {
      min-width: 70px;
      padding: 10px 16px;
      border-right: 1px solid #cccccc;

      &:last-child {
        border-right: 0px solid transparent;
      }
    }
  }

  &:last-child {
    border-right: 0px solid transparent;
  }

  .itemAlignCenter {
    display: flex;
    justify-content: center;
  }

  .itemAlignLeft {
    display: flex;
    justify-content: left;
  }

  .itemAlignRight {
    display: flex;
    justify-content: right;
  }

  .cvrImproveRateUp {
    color: #00b507;
    display: flex;
    justify-content: right;
  }

  .cvrImproveRateDown {
    color: #d03f3f;
    display: flex;
    justify-content: right;
  }

  .cvrImproveRateEven {
    color: #000000;
    display: flex;
    justify-content: right;
  }

  .arrowIcon {
    width: 6.5px;
    padding-left: 5px;
  }
}
