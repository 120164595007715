.pushNotificationEditorPanel {
  .errorMessages {
    margin-bottom: 15px;
  }

  .form {
    margin-bottom: 20px;
    .titleForm {
      max-width: 400px;
    }

    .ruleForm {
      max-width: 400px;
    }
  }

  .buttons {
    display: flex;
    justify-content: right;
  }
}
