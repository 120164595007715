.measurementUrlSettingEditForm {
  .errors {
    margin-bottom: 10px;
  }
  .panel {
    margin-bottom: 20px;

    .titleForm {
      max-width: 400px;
    }

    .locationForm {
      .text {
        width: 400px;
      }

      .patternSelect {
        width: 90px;
      }
    }

    .queryParameterForm {
      .text {
        width: 400px;
      }
      .separator {
        max-width: 520px;
      }
      .patternSelect {
        width: 90px;
      }
    }
  }
}
