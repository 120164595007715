.errorMessages {
  width: 100%;
  background-color: #f9eef1;
  padding: 5px 10px;
  border-radius: 5px;

  text-align: left;

  .message {
    color: #dc1340;

    .icon {
      margin-right: 5px;
      vertical-align: middle;
    }
  }

  .message:not(:last-child) {
    margin-bottom: 5px;
  }
}
