@import "variables";

.headlineText {
  margin: 0;
  padding: 0;
  white-space: nowrap;

  background-color: transparent;
  font-weight: bold;
  font-size: $headlineFontSize;
  line-height: $normalLineHeight;
  font-family: $normalTextFont;
  color: $textColor;
}
