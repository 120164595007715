@import "variables";

.locationsFormView {
  display: flex;

  .urlInputArea {
    display: flex;
    align-items: center;

    .urlInput {
      .location {
        flex-grow: 1;
        margin-bottom: 10px;
      }
    }
  }

  &__disabled {
    @extend .locationsFormView;
    filter: opacity(0.3);
  }
}
