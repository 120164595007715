.simplePresetColorPicker {
  box-sizing: border-box;

  .circleArea {
    margin-bottom: 10px;

    .circleRow {
      display: flex;
      margin-bottom: 5px;

      .circleElem {
        margin-right: 5px;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;

    .editableInput {
      margin-right: 10px;
    }
  }
}
