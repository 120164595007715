@import "variables";

.dateRangePickerContainer {
  box-sizing: border-box;
  background-color: white;
  border: 1px solid $dateRangePickerBorderColor;
  border-radius: 3px;
  box-shadow: 0px 0px 10px #0000001a;
  position: relative;
  padding: 15px 5px;

  .main {
    border: none;
    background-color: white;
    position: relative;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    background-color: white;
    text-align: right;
    padding-right: 10px;

    .cancel {
      margin-right: 10px;
    }
  }
}
