@import "variables";

.imageUploader {
  width: 265px;
}

.initialViewer {
  width: 265px;
  height: 101px;
  padding: 20px 0px;

  background-color: $imageUploaderBackgroundColor;
  border: 1px dashed $imageUploaderBorderColor;

  display: flex;

  justify-content: center;
  align-items: center;

  cursor: pointer;

  .container {
    text-align: center;

    .image {
      margin-bottom: 10px;
      .icon {
        width: 40px;
      }
    }

    .text {
      font-size: $normalFontSize;
      line-height: $normalLineHeight;
      font-weight: bold;
      color: $imageUploaderTextColor;
    }
  }
}

.imageViewer {
  position: relative;
  width: 265px;

  cursor: pointer;

  .image {
    width: 100%;
  }

  .reuploadButton {
    display: none;
  }

  &:hover {
    .image {
      @extend .image;
      filter: saturate(90%);
    }

    .reuploadButton {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;

      .text {
        padding: 3px 10px;
        background-color: #ffffff;
        border-radius: 43px;

        color: $imageUploaderTextColor;
        word-wrap: normal;
        line-height: $normalLineHeight;
        font-size: $normalFontSize;
        font-weight: bold;
      }
    }
  }
}
