.copyableLink {
  display: flex;
  align-items: center;

  .text {
    display: block;
    white-space: nowrap;
    margin-right: 5px;

    text-decoration: none;
    color: #ff5c38;
  }
}
