@import "variables";

.operation {
  display: flex;
  justify-content: center;
  align-items: center;

  .item {
    height: 14px;
    padding-right: 10px;

    &:last-child {
      padding-right: 0px;
    }
  }
}
