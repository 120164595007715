@import "variables";

.orangeText {
  color: #ff5c38;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }

  &__disable {
    @extend .orangeText;
    color: #ffcec3;

    &:hover {
      cursor: default;
    }
  }
}
