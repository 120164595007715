@import "variables";

.customFormatSelectButton {
  width: 270px;
  height: 170px;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $imageTextButtonColor;

  border-width: 2px;
  border-color: transparent;
  border-radius: $buttonBorderRadius;
  border-style: solid;

  font-size: $normalFontSize;

  &:hover {
    border-color: $hoveredImageTextButtonBorderColor;
  }

  .image {
    margin-bottom: 20px;
  }
}

.customFormatSelectButton__active {
  @extend .customFormatSelectButton;
  border-color: $hoveredImageTextButtonBorderColor;
}
