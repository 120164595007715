@import "variables";

.notificateModal {
  text-align: left;
  .title {
    margin-bottom: 10px;
  }

  .description {
    margin-bottom: 20px;
  }

  .buttons {
    margin-bottom: 0px;
  }
}
