@import "variables";

.input {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 5px 10px;
  height: 30px;

  border-width: 1px;
  border-style: solid;
  border-color: $formBorderColor;
  border-radius: $inputBorderRadius;

  box-shadow: none;
  outline: none;

  &:hover {
    border-color: $hoveredFormBorderColor;
  }

  &:focus {
    border-color: $focusedInputBorderColor;
  }

  &::selection {
    background-color: $inputSelectionColor;
  }
}

.input__error {
  @extend .input;
  border-color: $errorInputBorderColor;
}

.input__disabled {
  @extend .input;
  &:hover,
  &:focus {
    border-color: $formBorderColor;
  }
}
