@import "variables";

.actionView {
  width: 100%;

  .header {
    margin-bottom: 10px;
  }

  .contents {
    margin-bottom: 50px;
  }
}
