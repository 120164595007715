@import "variables";

.sourceEditView {
  width: auto;
  padding: 20px 30px;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    .title {
      font-size: $normalFontSize;
      font-weight: bold;
    }
  }
}
