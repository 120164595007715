.executeTimings {
  .executeTiming {
    .orText {
      width: 13px;
      height: 20px;
      margin-top: 5px;
      margin-bottom: 3px;
    }
    .executeTimingGroup {
      display: flex;
      .period {
      }
      .dayOfWeek {
        margin-top: 5px;
        margin-right: 5px;
      }
      .delete {
        margin-top: 7px;
        padding-left: 10px;
      }
    }
  }
}
