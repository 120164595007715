@import "variables";

.itemName {
  display: flex;
  align-items: center;

  .name {
    font-weight: bold;
    font-size: $normalFontSize;
    line-height: $normalLineHeight;
  }

  .requireIcon {
    margin-left: 5px;
  }

  .toolTip {
    margin-left: 5px;
    line-height: 14px;
  }
}
