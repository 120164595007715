.asteriskList {
  list-style-type: "※";
  list-style-position: outside;
  padding-left: 10px;
  margin-bottom: 10px;

  .item:not(:last-child) {
    margin-bottom: 10px;
  }
}
